import {
  ApolloError,
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  useLazyQuery as useApolloLazyQuery,
  useQuery,
  WatchQueryFetchPolicy,
} from "@apollo/client";
import { logError } from "apollo/utils";
import {
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "core/consts";
import { useActivityContext } from "core/model/utils/browser/ActivitySingleton";
import { ApolloEncryptionContext, QueryProgress } from "core/types";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getFetchPolicy, useApolloProgress } from "../utils";

export function getQueryProgress(
  loading: boolean,
  error: ApolloError | undefined,
  skip?: boolean,
): QueryProgress {
  if (loading) return QUERY_PROGRESS_PENDING;
  if (error) {
    logError(error);
    return QUERY_PROGRESS_FAILED;
  }
  if (skip) {
    return QUERY_PROGRESS_NOT_STARTED;
  }
  return QUERY_PROGRESS_SUCCEED;
}

export const usePolling = ({
  shouldPoll = true,
  startPolling,
  stopPolling,
  interval = 2 * 60 * 1000,
  dependencies = [],
}: {
  dependencies?: Array<any>;
  interval?: number;
  shouldPoll?: boolean;
  startPolling: (arg0: number) => void;
  stopPolling: () => void;
}) => {
  // control if the query ever started polling
  const [isPolling, setIsPolling] = useState(false);
  const { active } = useActivityContext();

  // init
  useEffect(() => {
    // start
    if (shouldPoll) {
      startPolling(interval);
      setIsPolling(true);
      // stop
    } else if (isPolling) {
      stopPolling();
      setIsPolling(false);
    }
  }, [shouldPoll]);

  // re-poll with dependecies that affect the query
  useEffect(() => {
    if (isPolling) {
      stopPolling();
      setTimeout(() => {
        startPolling(interval);
      }, 0);
    }
  }, [...dependencies]);

  // check for browser activity
  useEffect(() => {
    // stop polling if user is inactive
    if (!active && isPolling) {
      stopPolling();
      // restart polling if user is active
    } else if (active && !isPolling && shouldPoll) {
      setTimeout(() => {
        startPolling(interval);
      }, 0);
    }
  }, [active]);
};

export function useGenericQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(query: DocumentNode, options?: QueryHookOptions<TData, TVariables>) {
  const result = useQuery<TData, TVariables>(query, {
    ...options,
    fetchPolicy: getFetchPolicy(options?.fetchPolicy),
  });
  const { progress } = useApolloProgress(result);
  const { data, ...rest } = result;
  return [progress, data, rest] as const;
}

export function useLazyQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: Parameters<typeof useApolloLazyQuery<TData, TVariables>>[0],
  options?: Parameters<typeof useApolloLazyQuery<TData, TVariables>>[1] & {
    encryptionContext?: ApolloEncryptionContext;
  },
) {
  const [executeQuery, { data, error, loading }] = useApolloLazyQuery<
    TData,
    TVariables
  >(query, {
    fetchPolicy: options?.fetchPolicy ?? "network-only",
    context: { encryptionContext: options?.encryptionContext },
  });

  const execute = (variables?: TVariables) => {
    const mergedVariables = {
      ...(options?.variables ?? {}),
      ...(variables ?? {}),
    };

    return executeQuery({ variables: mergedVariables as TVariables });
  };

  const queryProgress = getQueryProgress(loading, error);

  return [execute, queryProgress, data, error] as const;
}

export const useGetAuctionRequestFetchPolicy = ({
  fetchPolicy,
}: {
  fetchPolicy?: WatchQueryFetchPolicy;
}): WatchQueryFetchPolicy => {
  const [search] = useSearchParams();

  const isRecommendation = search.get("recommendation");

  if (isRecommendation) {
    return "cache-first";
  }

  return fetchPolicy ?? "network-only";
};
